import {child, get, onValue, ref, set, update, remove} from "firebase/database";
import {apiKey, database, environment} from "../../../firebaseConfig";
import {v4 as uuidv4} from 'uuid';

export class DatabaseDAO {
    constructor() {
        this.environment = environment;
        this.apiKey = apiKey;
    }

    /**
     *
     * @param record
     * @param recordUrl
     * @returns {Promise<void>}
     */




    addRecord(record, recordUrl) {
        //if no id is provided, generate one
        console.log(record)
        if (!record.id) {
            record.id = uuidv4();
        }
        record.apiKey = apiKey;
        const saveRecordUrl = `${this.environment}/${recordUrl}/${record.id}`;
        return set(ref(database, saveRecordUrl), record);
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @param record
     * @returns {Promise<void>}
     */
    async updateRecord(recordId, recordUrl, record) {
        const saveRecordUrl = `${this.environment}/${recordUrl}/${recordId}`;
        const updates = {};
        let existing = await this.getRecord(recordId, recordUrl)
        updates[saveRecordUrl] = {...existing, ...record};
        return update(ref(database), updates);
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @returns {Promise<void>}
     */
    deleteRecord(recordId, recordUrl) {
        const saveRecordUrl = `${this.environment}/${recordUrl}/${recordId}`;
        return remove(ref(database, saveRecordUrl));
    }

    /**
     *
     * @param recordId
     * @param recordUrl
     * @returns {Promise<DataSnapshot>}
     */
    getRecord(recordId, recordUrl) {
        const dbRef = ref(database);
        const saveRecordUrl = `${this.environment}/${recordUrl}/${recordId}`;

        return get(child(dbRef, saveRecordUrl))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    console.log("No data available");
                    return {};
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /**
     *
     * @param recordUrl
     * @returns {Promise<DataSnapshot>}
     */

    getRecords(recordUrl) {
        const dbRef = ref(database);
        return get(child(dbRef, `${this.environment}/${recordUrl}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return snapshot.val();
                } else {
                    console.log("No data available");
                    return [];
                }
            })
            .catch((error) => {
                console.log("There is an error");
                console.error(error);
            });
    }

    filterObjects(array, filterKeys) {
        return array.filter((obj) =>
            Object.entries(filterKeys).every(([key, value]) => obj[key] === value)
        );
    }

    filterRecord(recordUrl, filter) {
        const dbRef = ref(database);
        return get(child(dbRef, `${this.environment}/${recordUrl}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    return this.filterObjects(Object.values(snapshot.val()), filter);
                } else {
                    console.log("No data available");
                    return [];
                }
            })
            .catch((error) => {
                console.log("There is an error");
                console.error(error);
            });

    }

    listenRecordChanges(recordUrl, callback) {
        const recordRef = ref(database, `${this.environment}/${recordUrl}`);
        onValue(recordRef, (snapshot) => {
            return callback(snapshot.val());
        });
    }

}

export default new DatabaseDAO();
